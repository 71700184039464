<template>
    <div>

        <div class="modal_header">
            <el-row :gutter="10">
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <div class="grid-content bg-purple">
                        <div class="title_h">
                           Новый роль
                        </div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <div class="grid-content bg-purple-light">
                        <el-row class="button_r">
                            <el-button type="success">Сохранить и закрыть</el-button>
                            <el-button>Закрыть</el-button>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </div>

    <el-form ref="form" :model="form" class="aticler_m">
        <el-row :gutter="20">

            <el-col :span="10">
                <el-form-item label="Наименование">
                    <el-input v-model="form.namePole" size="mini"></el-input>
                </el-form-item>
            </el-col>
            <!-- end col -->

            <el-col :span="10">
                <el-form-item label="Слизень">
                    <el-input v-model="form.slug" size="mini"></el-input>
                </el-form-item>
            </el-col>
            <!-- end col -->
            <el-col :span="24">
               
                <el-tree
                    :props="props"
                    :load="loadNode"
                    lazy
                    show-checkbox>
                </el-tree>
                <el-tree
                    :props="props"
                    :load="loadNode1"
                    lazy
                    show-checkbox>
                </el-tree>
               
            </el-col>

        </el-row>
        <!-- end row -->
    </el-form>

    </div>
</template>
<script>
  export default {
    data() {
        return {
            form: {
                namePole: '',
                slug: '',

            },
            props: {
                label: 'name',
                children: 'zones',
                isLeaf: 'leaf'
            }
        }
    },
    methods: {
        loadNode(node, resolve) {
            if (node.level === 0) {
                return resolve([{ name: 'region' }]);
            }
            if (node.level > 1) return resolve([]);

            setTimeout(() => {
            const data = [{
                name: 'leaf',
                leaf: true
            }, {
                name: 'zone',
                leaf: true
            },
            {
                name: 'zone',
                leaf: true
            }];

            resolve(data);
            }, 500);
        },loadNode1(node, resolve) {
            if (node.level === 0) {
                return resolve([{ name: 'region' }]);
            }
            if (node.level > 1) return resolve([]);

            setTimeout(() => {
            const data = [{
                name: 'leaf2',
                leaf: true
            }, {
                name: 'zone',
                leaf: true
            },
            {
                name: 'zone',
                leaf: true
            }];

            resolve(data);
            }, 500);
        }
    }
    

  }
  
</script>